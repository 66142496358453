import React from "react";
import Helmet from "react-helmet";

const SEO_DATA = {
  description:
    "Praxis für Physiotherapie und Osteopathie von Maike Lübeck-Grießhaber in St. Georgen im Schwarzwald",
  title: "Physiotherapie & Osteopathie Maike Lübeck-Grießhaber",
  url: "https://physio-luebeck-grieshaber.de/",
  author: "Maike Lübeck-Grießhaber",
  keywords: [
    "physiotherapie",
    "physio",
    "physiotherapeut",
    "physiotherapeutin",
    "physio therapie",
    "osteopathie",
    "osteopath",
    "osteopathin",
    "osteo",
    "st. georgen",
    "sankt georgen",
    "st goergen",
    "villingen",
    "villingen-schwenningen",
    "villingen schwenningen",
    "triberg",
    "peterzell",
    "lorenzhaus",
    "schwarzwald",
    "maike Lübeck-Grieshaber",
    "maike lübeck",
    "lübeck-grieshaber",
    "lübeck",
    "krankengymnastik",
    "KG"

  ],
  img: "",
};

const SEO = () => {
  return (
    <Helmet>
      <meta property="og:type" content="website" />
      <meta property="og:url" content={SEO_DATA.url} />
      <meta property="og:description" content={SEO_DATA.description} />

      <meta name="description" content={SEO_DATA.description} />
      <meta name="keywords" content={SEO_DATA.keywords.join(", ")} />
      <meta name="author" content={SEO_DATA.author} />
      <title>{SEO_DATA.title}</title>
      <html lang="de" />
    </Helmet>
  );
};

export default SEO;
